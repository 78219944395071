.nanum-myeongjo-regular {
	font-family: "Nanum Myeongjo", serif;
	font-weight: 400;
	font-style: normal;
}

.nanum-myeongjo-bold {
	font-family: "Nanum Myeongjo", serif;
	font-weight: 700;
	font-style: normal;
}

.nanum-myeongjo-extrabold {
	font-family: "Nanum Myeongjo", serif;
	font-weight: 800;
	font-style: normal;
}

.gaegu-light {
	font-family: "Gaegu", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.gaegu-regular {
	font-family: "Gaegu", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.gaegu-bold {
	font-family: "Gaegu", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.app {
	justify-content: center;
	align-items: center;
	text-align: center;
	min-width: 240px;
	font-family: "Gaegu", sans-serif;
	height: 100vh;

	background-image: url(../../asset/img/grass_background.jpeg);
	background-size: contain;
	z-index: auto;
}

.title {
	font-size: 40px;
	padding-top: 10vw;
	padding-bottom: 5vw;
	text-decoration-line: underline;
	text-decoration-style: wavy;
	text-decoration-color: rgb(255, 111, 111);
	/* z-index: 1; */
}

.todoText {
	word-break: break-all;
	text-align: left;
	padding: 10px 20px 10px 30px;
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
	align-self: center;
}

.todoIconX {
	padding: 10px;
	justify-content: flex-end;
	margin-right: 7vw;
	max-width: 70px;
	cursor: pointer;
	align-content: center;
}

input[type="checkbox"] {
	transform: scale(1.5);
	margin-left: 6vw;
	cursor: pointer;
}

button:hover {
	cursor: pointer;
}

html {
	background-color: black;
	color: white;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-family: "Gaegu", sans-serif;
}

.done {
	word-break: break-all;
	text-align: left;
	padding: 10px 10px 10px 20px;
	text-decoration: line-through;
	color: gray;
}

.inputWrapper {
	width: 300px;
	font-size: 15px;
	font-family: "Gaegu", sans-serif;

	/* text-align: center; */
	align-self: center;
	margin: 20px;
	padding: 10px;
	border-radius: 10px;
	background-color: rgb(36, 36, 36);
	outline: none;
	/* box-shadow: 0.3em 0.3em 10px #ff6767; */
	border: none;
	color: white;
	/* max-width: 45vw; */
}

.postButton {
	max-width: 70px;
	min-width: 70px;
	font-size: 15px;
	font-family: "Gaegu", sans-serif;
	font-weight: 700;
	align-self: center;
	/* margin: 20px; */
	padding: 10px;
	border-radius: 30px;
	/* box-shadow: inset 0.3em 0.3em 12px #fffbf0; */
	background-color: rgb(65, 61, 192);
	outline: none;
	border: none;
	color: whitesmoke;
}

.todolist {
	/* display: flex; */
	justify-content: center;
	text-align: center;
	align-self: center;
}

.contentBox {
	display: flex;
	border-radius: 10px;
	box-shadow: 0 0 10px rgb(26, 26, 26);
	padding: 10px;
	margin: 5px 0;
	justify-content: space-between;
	font-size: 20px;
	background-color: rgb(200, 182, 158);
	align-items: center;
	min-width: 100px;
}
